import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import PracticeHistoryQuestions from "../practiceHistoryQuestions/PracticeHistoryQuestions";
import Alert from "../../../components/customMui/Alert";
import Button from "../../../components/customMui/Button";
import LoadingButton from "../../../components/customMui/LoadingButton";
import CircularProgress from "../../../components/customMui/CircularProgress";
import Modal from "../../../components/customMui/modal/Modal";
import Skeleton from "@mui/material/Skeleton";
import standardBizApiClient from "../../../util/BizApi/standardBizApiClient";
import { getSentenceTimeFromSeconds } from "../../../util/functions/time";
import pagePath from "../../../util/constants/pagePath";
import getCssVariable from "../../../util/functions/getCssVariable";
import { LinearProgress } from "@mui/material"; 
import "./practiceFull.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const PracticeFull = () => {
    const { certList, certCodeInFocus, selectedNumberOfQuestions } = useSelector((state) => state.practice);
    const [showPageLoader, setShowPageLoader] = useState(true);
    const [formattedCert, setFormattedCert] = useState(null);
    const [showStartFullModal, setShowStartFullModal] = useState(false);
    const [showStartFullLoader, setShowStartFullLoader] = useState(false);
    const [startFullError, setStartFullError] = useState(null);
    const [examHistoryLoading, setExamHistoryLoading] = useState(false);
    const [examHistory, setExamHistory] = useState(null);
    const [showTestResultsLoader, setShowTestResultsLoader] = useState(null);
    const [testResultsExamSummary, setTestResultsExamSummary] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [deactivateErrorMessage, setDeactivateErrorMessage] = useState(null);
    const [showRefreshModal, setShowRefreshModal] = useState(false);
    const [isRefreshChecked, setIsRefreshChecked] = useState(false);
    const [isRefreshDisabled, setIsRefreshDisabled] = useState(true);
    const [showProcessingModal, setShowProcessingModal] = useState(false); 
    const [isProcessing, setIsProcessing] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [progress, setProgress] = useState(0); 

    useEffect(() => {
        handleFormatCert();
    }, [certList, certCodeInFocus]);

    useEffect(() => {
        refreshExamHistory();
    }, [certCodeInFocus]);

    const handleRefreshModalOpen = () => {
        setShowRefreshModal(true);
    };
    
    const handleRefreshModalClose = () => {
        setShowRefreshModal(false);
    };
    
    const handleRefreshConfirm = async () => {
        setShowRefreshModal(false); 
        setShowProcessingModal(true); 

        setTimeout(async () => {
            try {
                setIsProcessing(true);
                let progressValue = 0;
                const progressInterval = setInterval(() => {
                    if (progressValue < 100) {
                        progressValue += 10; 
                        setProgress(progressValue);
                    }
                }, 2000);

                const removeCertHistoryRes = await standardBizApiClient.remove_cert_history();

                if (removeCertHistoryRes?.is_error) {
                    setDeactivateErrorMessage(removeCertHistoryRes.message || "Error while clearing the history, please try again.");
                } else {
                    setSuccessMessage(
                        <span>
                            <i className="fa fa-check" style={{ marginRight: "8px", color: "green" }}></i>
                            successfully completed Reset Exam History.
                        </span>
                    );
                    setTimeout(() => {
                        clearInterval(progressInterval); 
                        setShowProcessingModal(false); 
                        window.location.reload(); 
                    },2000); 
                }
            } catch (error) {
                console.error("Error removing certificate history:", error);
                setDeactivateErrorMessage("Unexpected error occurred while refreshing history.");
            } finally {
                setIsProcessing(false);
            }
        }, 500); 
    };
    
    const handleCheckboxChange = () => {
        setIsRefreshDisabled(isRefreshChecked);
        setIsRefreshChecked(!isRefreshChecked);
    };
    
    const handleFormatCert = () => {
        let newFormattedCert = certList.filter(cert => cert.cert.code === certCodeInFocus);
        if (newFormattedCert.length === 0) {
            setShowPageLoader(false);
            return;
        }
        newFormattedCert = {
            ...newFormattedCert[0]
        };
        setFormattedCert(newFormattedCert);
        setShowPageLoader(false);
    }

    const refreshExamHistory = async () => {
        if (examHistoryLoading) return;

        setErrorMessage(null);
        setExamHistory(null);
        setExamHistoryLoading(true);
        const fullExamHistoryRes = await standardBizApiClient.exam_full_history();
        if (fullExamHistoryRes?.is_error) {
            setErrorMessage("Error while gathering full test history, please try again soon.");
            setExamHistoryLoading(false);
            return;
        }
        setExamHistory(fullExamHistoryRes.full_practice_exams);
        setExamHistoryLoading(false);
    }

    const handleStartFullPracticeExam = async () => {
        if (showStartFullLoader) return;
        setShowStartFullLoader(true);
        setStartFullError(null);
        const createFullPracticeExamRes = await standardBizApiClient.exam_create_full();
        if (createFullPracticeExamRes?.is_error) {
            setStartFullError("Error while creating full practice test, please try again soon.");
            setShowStartFullLoader(false);
            return;
        }
        navigate(pagePath.practice_test);
    }

    const handleShowStartFullModal = () => {
        setShowStartFullModal(true);
    }

    const handleHideStartFullModal = () => {
        // prevent user closing modal while test is being created
        if (showStartFullLoader) return;
        setShowStartFullModal(false);
    }

    const handleShowTestResultsModal = async (examId) => {
        if (showTestResultsLoader) return;

        setErrorMessage(null);
        setShowTestResultsLoader(examId);
        const examSummaryRes = await standardBizApiClient.exam_summary(examId);
        if (examSummaryRes?.is_error) {
            setErrorMessage("Error while gathering test details, please try again soon.");
            setShowTestResultsLoader(null);
        }
        setTestResultsExamSummary(examSummaryRes);
        setShowTestResultsLoader(null);
    }

    const handleHideTestResultsModal = () => {
        setTestResultsExamSummary(null);
    }


    if (showPageLoader) {
        return null;
    }

    if (!formattedCert) {
        return (
            <Alert severity="error">Error while gathering full test details, please try again soon.</Alert>
        )
    }

    const historyListItemSkelton = (
        <div className="practice-full-history-list-item">
            <CircularProgress
                value={0}
                min={0}
                max={100}
                size={60}
                thickness={4}
                color={getCssVariable("--wnp-color-incorrect")}
                backgroundColor={null}
                label={null}
            />
            <div className="practice-full-history-list-item-data-list">
                <Skeleton animation="wave" sx={{ width: "133px" }} />
                <Skeleton animation="wave" sx={{ width: "105px" }} />
            </div>
            <Skeleton animation="wave" sx={{ height: "40px", width: "100px" }} />
        </div>
    );

    return (
        <div>
            <Modal
                title={<h5>Full Practice Test</h5>}
                onCloseIconClick={handleHideStartFullModal}
                customProps={{
                    open: showStartFullModal,
                    onClose: handleHideStartFullModal
                }}
            >
                {startFullError ? <Alert severity="error">{startFullError}</Alert> : null}

                <p className="practice-full-detail">
                    The full practice test covers all {formattedCert.cert.name} domains.
                </p>
                <div className="practice-full-detail-list-container">
                    <div className="practice-full-detail-list-item">
                        <p className="practice-full-detail-list-item-field font-header-bold">Questions:</p>
                        <p className="practice-full-detail-list-item-value">{formattedCert.full_test.total_question_count}</p>
                    </div>
                    <div className="practice-full-detail-list-item">
                        <p className="practice-full-detail-list-item-field font-header-bold">Time&nbsp;Limit:</p>
                        <p className="practice-full-detail-list-item-value">{getSentenceTimeFromSeconds(formattedCert.full_test.time_limit_seconds, false).sentence}</p>
                    </div>
                    <div className="practice-full-detail-list-item">
                        <p className="practice-full-detail-list-item-field font-header-bold">Passing&nbsp;Score:</p>
                        <p className="practice-full-detail-list-item-value">{formattedCert.full_test.passing_score_factor * 100 + "%"}</p>
                    </div>
                </div>

                <div className="practice-full-start-button-container">
                    <Button
                        variant="outlined"
                        customProps={{
                            onClick: handleHideStartFullModal,
                            disabled: showStartFullLoader
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        customProps={{
                            onClick: handleStartFullPracticeExam,
                            loading: showStartFullLoader
                        }}
                    >
                        Start
                    </LoadingButton>
                </div>
            </Modal>

            <Modal
                title={<h5>Test Results</h5>}
                onCloseIconClick={handleHideTestResultsModal}
                disableChildrenTopSpacing={true}
                customProps={{
                    open: testResultsExamSummary !== null,
                    onClose: handleHideTestResultsModal
                }}
            >

                <PracticeHistoryQuestions examSummary={testResultsExamSummary} />
            </Modal>

            {errorMessage ? <Alert severity="error">{errorMessage}</Alert> : null}

            {/* <p>note for later. number of full tests taken, average score, average time taken, etc go here.</p> */}
            <div className="practice-full-description">
                <Alert severity="info">
                    A timed simulated exam, with questions drawn proportionally from each Domain in accordance with the official Exam Outline. 
                    Results shown upon completion.
                </Alert>
            </div>

            {/* Start Full Practice Test Button */}
            <div className="practice-full-open-start-form-button">
                <Button
                    customProps={{
                        onClick: handleShowStartFullModal
                    }}
                >
                    Start Full Practice Test
                </Button>
            </div>

            {examHistoryLoading ? (
                <div>
                    <hr className="practice-full-history-hr" />
                     {/* <h5 className="practice-full-history-title">History</h5> */}
                    {historyListItemSkelton}
                    {historyListItemSkelton}
                    {historyListItemSkelton}
                </div>
            ) : null}

            {/* Display Exam History */}
            {examHistory ? (
                <div>
                    <hr className="practice-full-history-hr" />

                    {examHistory.length === 0 ? (
                        <Alert severity="info">After completing your first {formattedCert.cert.name} full practice test, check back here to view its results.</Alert>
                    ) : null}

                    {examHistory.map((exam, index) => {
                        const createdDt = moment.utc(exam.created_dt);
                        const createdDate = createdDt.local().format("M/D/YYYY h:mm A");
                        return (
                            <div key={index} className="practice-full-history-list-item">
                                <CircularProgress
                                    value={Math.floor(exam.score_factor * 100)}
                                    min={0}
                                    max={100}
                                    size={60}
                                    thickness={4}
                                    color={exam.score_factor >= formattedCert.full_test.passing_score_factor ? getCssVariable("--wnp-color-correct") : getCssVariable("--wnp-color-incorrect")}
                                    backgroundColor={null}
                                    label={Math.floor(exam.score_factor * 100) + "%"}
                                />
                                <div className="practice-full-history-list-item-data-list">
                                    <p>{createdDate}</p>
                                    {exam.time_spent_seconds >= 60 ? (
                                        <p>
                                            {getSentenceTimeFromSeconds(exam.time_spent_seconds).just_minutes}&nbsp;
                                            {getSentenceTimeFromSeconds(exam.time_spent_seconds).just_minutes === 1 ? "minute" : "minutes"}&nbsp;spent
                                        </p>
                                    ) : (
                                        <p>{exam.time_spent_seconds} seconds spent</p>
                                    )}
                                </div>
                                <LoadingButton
                                    customProps={{
                                        onClick: () => handleShowTestResultsModal(exam.practice_exam_id),
                                        loading: showTestResultsLoader === exam.practice_exam_id,
                                        sx: { width: "100px" }
                                    }}
                                >
                                    Details
                                </LoadingButton>
                            </div>
                        )
                    })}

                    {/* Refresh Button */}
                    <div className="practice-full-refresh-button">
                        <Button
                            customProps={{
                                onClick: handleRefreshModalOpen, // Call the existing refresh function
                            }}
                        >
                            Reset complete practice test History
                        </Button>
                    </div>

                    {/* Modal for Reset History Confirmation */}
                    <Modal
                        title={<h5>Reset Exam History</h5>}
                        onCloseIconClick={handleRefreshModalClose}
                        customProps={{
                            open: showRefreshModal,
                            onClose: handleRefreshModalClose,
                        }}
                    >
                        <div className="account-manage-modal">
                            {deactivateErrorMessage ? (
                                <Alert
                                    severity="error"
                                    style={{ textAlign: "left", marginBottom: "20px" }}
                                >
                                    {deactivateErrorMessage}
                                </Alert>
                            ) : null}

                            <Alert
                                severity="warning"
                                style={{ textAlign: "left", marginBottom: "20px" }}
                            >
                                Once you confirm Reset; all previously recorded domain and full test exam results history will be deleted.
                            </Alert>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isRefreshChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label="Please check the box to confirm Reset History"
                            />
                            <div className="account-manage-modal-button-container">
                                <Button
                                    variant="contained"
                                    customProps={{
                                        onClick: handleRefreshModalClose,
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    buttonColor="#a31c1c"
                                    buttonColorSelected="#a31c1c"
                                    buttonColorFilledBackground="#c92222"
                                    customProps={{
                                        onClick: handleRefreshConfirm,
                                        disabled: isRefreshDisabled,
                                    }}
                                >
                                    Confirm Reset Exams
                                </Button>
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        title={<h5>Reset Exam History Processing</h5>}
                        customProps={{
                            open: showProcessingModal,
                            onClose: () => {}, 
                        }}
                    >
                        <div style={{ textAlign: "center" }}>
                            {isProcessing ? (
                                <div>
                                    <LinearProgress variant="determinate" value={progress} />
                                    <p style={{ textAlign: "center" }}>Progress: {progress}%</p>
                                </div>
                            ) : (
                                <p style={{ color: "green" }}>{successMessage}</p>
                            )}
                            
                        </div>
                    </Modal>
                </div>
            ) : null}
        </div>
    );
};

export default PracticeFull;
