// handles errors from the client. uses an injected error transformation strategy to format errors accordingly

class ClientErrorDecorator {
    constructor(client, errorStrategy) {
        this.client = client;
        this.errorStrategy = errorStrategy;
    }
  
    healthcheck = async () => {
        try {
            const response = await this.client.healthcheck();
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    account_ping = async () => {
        try {
            const response = await this.client.account_ping();
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    account_signup = async (emailAddress, password) => {
        try {
            const response = await this.client.account_signup(emailAddress, password);
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    account_signup_confirm = async (emailAddress, token) => {
        try {
            const response = await this.client.account_signup_confirm(emailAddress, token);
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    account_login = async (emailAddress, password, captchaToken) => {
        try {
            const response = await this.client.account_login(emailAddress, password, captchaToken);
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    account_support_login = async (userId, token, session) => {
        try {
            const response = await this.client.account_support_login(userId, token, session);
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    account_signout = async () => {
        try {
            const response = await this.client.account_signout();
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    account_reset_password_initiate = async (emailAddress) => {
        try {
            const response = await this.client.account_reset_password_initiate(emailAddress);
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    account_reset_password = async (password, token) => {
        try {
            const response = await this.client.account_reset_password(password, token);
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    account_deactivate = async ()=> {
        try {
            const response = await this.client.account_deactivate();    
            return response.data;
        } catch(error){
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    account_set_payment_method = async (paymentMethodId) => {
        try {
            const response = await this.client.account_set_payment_method(paymentMethodId);    
            return response.data;
        } catch(error){
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    account_get_payment_method = async () => {
        try {
            const response = await this.client.account_get_payment_method();    
            return response.data;
        } catch(error){
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    get_captcha_site_key = async () => {
        try {
            const response = await this.client.get_captcha_site_key();    
            return response.data;
        } catch(error){
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    certification_subscribe = async (certificationCode, promoCode=null) => {
        try {
            const response = await this.client.certification_subscribe(certificationCode, promoCode);
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    certification_unsubscribe = async (certificationCode) => {
        try {
            const response = await this.client.certification_unsubscribe(certificationCode);
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    certification_set_focus = async (certificationCode) => {
        try {
            const response = await this.client.certification_set_focus(certificationCode);
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    certification_list = async () => {
        try {
            const response = await this.client.certification_list();
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    certification_get_market_price = async (certificationCode, promoCode=null) => {
        try {
            const response = await this.client.certification_get_market_price(certificationCode, promoCode);
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    certification_detail = async (certificationCode) => {
        try {
            const response = await this.client.certification_detail(certificationCode);
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    certification_subscription_list = async () => {
        try {
            const response = await this.client.certification_subscription_list();
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    exam_meta = async () => {
        try {
            const response = await this.client.exam_meta();
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    exam_domain_history = async () => {
        try {
            const response = await this.client.exam_domain_history();
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    exam_full_history = async () => {
        try {
            const response = await this.client.exam_full_history();
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    exam_ping = async () => {
        try {
            const response = await this.client.exam_ping();
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    exam_summary = async (examId) => {
        try {
            const response = await this.client.exam_summary(examId);
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    exam_create_domain_practice = async (domainId, questionCount) => {
        try {
            const response = await this.client.exam_create_domain_practice(domainId, questionCount);
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    exam_create_full = async () => {
        try {
            const response = await this.client.exam_create_full();
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    remove_cert_history = async () => {
        try {
            const response = await this.client.remove_cert_history();
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    exam_discard = async () => {
        try {
            const response = await this.client.exam_discard();
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    exam_next_question = async () => {
        try {
            const response = await this.client.exam_next_question();
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    exam_answer_question = async (selection, examType) => {
        try {
            const response = await this.client.exam_answer_question(selection, examType);
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }

    param_stripe_pub_key = async () => {
        try {
            const response = await this.client.param_stripe_pub_key();
            return response.data;
        } catch(error) {
            const parsedError = this.errorStrategy.parse(error);
            return parsedError;
        }
    }
}

export default ClientErrorDecorator;